.head {
  color: rgb(36, 48, 180);
  display: flex;
  justify-content: center;
  position: relative;
  overflow-wrap: normal;

}

.set {
  padding: 15px 0px;
  margin-left: 10px;
}

.imgset {
  max-height: 580px !important;
  min-height: 280px !important;

}

.carousel-control-prev {
  margin-top: 150px;
}

.carousel-control-next {
  margin-top: 150px;
}


.back {
  margin-bottom: 0px !important;


}

.headerContainer {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
}



.headerList {
  display: flex;
  gap: 40px;
  margin-bottom: 50px;
}

.headerListItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerListItem.active {
  border: 1px solid white;
  padding: 10px;
  border-radius: 20px;
}

.headerDesc {
  margin: 50px 0px;
}

.headerBtn {
  background-color: #48a0c6;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 50px;
  width: 300px !important;
  padding: 15px 10px;
  cursor: pointer;
  position: relative;
  top: 40px;
  margin: 0px auto;
  margin-bottom: 25px;

}


.headerSearch {

  background-color: white;
  /* box-shadow: 10px 0 45px rgba(0, 0, 0,); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  align-items: center;
  border: 1px solid #0071c2;
  justify-content: space-around;

  border-radius: 5px;
  position: absolute;
  bottom: -25px;

  max-width: 1024px;
}

.headerIcon {
  color: lightgray;
  font-size: 50px;
}

.headerSearchItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerSearchInput {
  border: none;
  outline: none;
}

.headerSearchText {
  border: none !important;
  color: #757586 !important;
  font-weight: 600 !important;
  font-size: 20px;
}

.date {
  position: absolute;
  top: 50px;
  width: 350px;
  z-index: 2;
}

.options {
  z-index: 2;
  position: absolute;
  top: 250px;
  background-color: white;
  color: gray;
  border-radius: 5px;
  width: 250px;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);

}

.optionItem {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.optionCounter {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: black;
}

.optionCounterButton {
  width: 30px;
  height: 30px;
  border: 1px solid #0071c2;
  color: #0071c2;
  cursor: pointer;
  background-color: white;
}

.optionCounterButton:disabled {
  cursor: not-allowed;
}

.profile:hover {
  background-color: white;
  color: black !important;
}

.shad {

  margin-top: 0px !important;
  z-index: 99999;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.carousel-caption {
  top: 0;
  left: 0;
  bottom: 0%;
  right: 0%;
  background: rgba(0, 0, 0, 0.5) !important;

}

.nav-item:hover {
  color: white !important;
}

.fa:hover {
  color: #0d6efd !important;
}

.navbar {
  background-color: #0f172b !important;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.zind {
  z-index: 9997;
}

.nav-link {
  color: rgb(226, 225, 221) !important;
}

a {
  text-decoration: none;
  color: black;
}

.mlogo {
  border-radius: 10px !important;
}

.setsuggestion {
  margin-top: -35px !important;
  color: rgb(179, 179, 179) !important;
  color: black !important;
  background-color: rgb(224, 224, 224);

  width: 23%;
  min-width: 200px;
  z-index: 9999 !important;
}

.setinput {
  border: none !important;
  outline: orange !important;
  font-weight: 600 !important;
  font-size: 20px;
  background-color: rgb(250, 250, 250);
}

.setcity {
  color: rgb(39, 39, 39);
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  margin: 10px !important;

}

.rdrCalendarWrapper {
  margin-top: 200px;
  margin-left: 0%;
}

.setbro {

  border-radius: 20px;
  margin: 1px;
}

.rdrCalendarWrapper {
  margin-left: -28px !important;
  margin-top: 300px;
}

.setsec {
  background-color: rgb(253, 253, 253);
  border-radius: 30px;
  border: 1px solid #48a0c6;
}

.cabutoon{
  background-color: #48a0c6 ;
 padding: 10px 30px;
 border-radius: 6%;
 color: white;
 max-width: 500px;
 margin: 20px auto;
  border: 1px solid  #48a0c6;
}