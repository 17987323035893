.pList {
  width: 100%;
  
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 15px;

}

.pListItem {

  margin: 10px;
  border: 1px solid gray;
  flex: 1;

  border-radius: 10px;

  cursor: pointer;
}

.pListImg {
  margin-top: 10px;
  width: 100%;
  height: 250px;
  min-width: 200px;
  object-fit: cover;
}

.pListTitles>h1 {
  font-size: 18px;
  color: #444;
  text-transform: capitalize;
  display: inline-flexbox;
}

.pListTitles>h2 {
  font-size: 14px;
  font-weight: 300;
}

.Listland {
  padding-top: 4%;
  padding-bottom: 2%;
  background-color: rgb(39, 39, 43);
  height: 100%;

}

.change {
  color: #47a0c4;
  color: rgb(144, 218, 247);
  color: #F38552;
  font-weight: 600;
  font-size: 50px;
}

.ret {
  margin-top: 5%;
  font-size: 55px;
  background-color: whitesmoke;
  padding-left: 10px;
  border-radius: 10px;
  color: #0071c2
}

.bg {
  background-color: rgb(95, 95, 95);
  background-color: rgb(39, 39, 43);

  border-radius: 3%;
}

.nana {
  border-radius: 3%;
  max-width: 100%;
  max-height: 100%;
}

.masa {
  margin-top: 10%;
  font-size: 19px;
  color: rgb(227, 227, 227);
}