h1 {
    text-align: center !important;
  }
  
  .setimg {
    border: 1px solid #0f172b;
    margin: 5px !important;
    border-radius: 3%;
    padding: 5px;
  
  
  
  
  }
  
  .mistext {
    color: rgb(62, 62, 62);
    font-size: 20px;
  }
  
  .hide {
    display: none;
  }
  
  .myDIV:hover+.hide {
    padding: 15px;
    display: block;
    color: rgb(43, 57, 119);
    font-weight: 500;
  }
  
  .imgsetss {
    height: 400px;
    width: 100%;
    border-radius: 3%;
  }
  
  .imgfix {
    overflow-x: auto;
  
  }
  
  .headshort {
    color: #48a0c6;
    font-weight: 700;
    font-size: 30px;
    font-family: ' Arial, sans-serif';
  }
  
  .headshorts {
    color: #48a0c6;
    font-weight: 900;
    margin: 30px 10px;
    font-size: 40px;
    font-family: ' Arial, sans-serif';
  }
  .member{
    font-size: 18px;
    color: #707070;
    font-weight: 600;
  }

  .compname{
    color: white;
    font-weight: 600;
  }
  .comp{
    color:  #48a0c6;
    font: uppercase;
    font-weight: 600;
  }
  .missson{
    width: 100%;
    height: 100%;
  }
  .abos{
    background-color: #48a0c6;
    color: rgb(235, 235, 235);
    padding: 50px 30px;
    margin-top: 30px;
  }

  .our-team{
    position: relative;
    overflow: hidden;
}
.our-team img{
    width: 100%;
    height: auto;
    transform: scale(1.2,1.2) translateX(-25px);
    transition: all 0.20s linear 0s;
}
.our-team:hover img{
    transform: scale(1.2,1.2) translateX(0);
}
.our-team .over-layer{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    position: absolute;
    top: 0;
    left: 0;
}
.our-team .team-content{
    width: 90%;
    position: absolute;
    bottom: -40px;
    padding: 0 25px;
    transition: all 0.40s ease 0s;
}
.our-team:hover .team-content{
    bottom: 1%;
}
.our-team .title{
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin: 20px 0 5px;
    text-transform: uppercase;
}
.our-team .post{
    display: block;
    font-size: 17px;
    color: #fff;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.our-team .description{
    font-size: 15px;
    color: #fff;
    transform: translateY(100%);
    transition: all 0.30s ease 0.1s;
}
.our-team:hover .description{
    transform: translateY(0);
}
.our-team .social-links{
    margin: 0;
    padding: 0;
    list-style: none;
    width: 10%;
    height: 100%;
    background: #48a0c6;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
}
.our-team .social-links li{
    margin: 20px 0;
}
.our-team .social-links li a{
    color: #fff;
    padding: 10px 0;
}
.our-team .social-links li a:hover{
    text-decoration: none;
}
@media only screen and (max-width: 990px){
    .our-team{ margin-bottom: 20px; }
}