
.contact-parent {
    background: #fff;
    display: flex;
    padding: 80px 0;
  }
  
  .contact-child {
    display: flex;
    flex: 1;
    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
  }
  
  .child1 {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://cdn.pixabay.com/photo/2019/06/28/00/17/architecture-4303279_1280.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #fff;
    padding: 100px 0;
  }
  
  .child1 p {
    padding-left: 20%;
    font-size: 20px;
    text-shadow: 0px 0px 2px #000;
  }
  
  .child1 p span {
    font-size: 16px;
    color: #9df2fd;
  }
  
  .child2 {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
  .inside-contact {
    width: 90%;
    margin: 0 auto;
  }
  
  .inside-contact h2 {
    text-transform: uppercase;
    text-align: center;
    margin-top: 50px;
  }
  
  .inside-contact h3 {
    text-align: center;
    font-size: 16px;
    color: #48a0c6;
  }

  
  .inside-contact input,
  .inside-contact textarea {
    width: 100%;
    background-color: #eee;
    border: 1px solid rgba(0, 0, 0, 0.48);
    padding: 5px 10px;
    margin-bottom: 20px;
  }
  
  .inside-contact input[type=submit] {
    background-color: #48a0c6;
    color: #fff;
    transition: 0.2s;
    border-radius: 5px;
    margin: 30px 0;
  }
  
  .inside-contact input[type=submit]:hover {
    background-color: #fff;
    color: #000;
    transition: 0.2s;
  }
  
  @media screen and (max-width:991px) {
    .contact-parent {
      display: block;
      width: 100%;
    }
  
    .child1 {
      display: none;
    }
  
    .child2 {
      background-image: linear-gradient(rgba(255, 255, 255, ), rgba(255, 255, 255, 0.7)), url("https://cdn.pixabay.com/photo/2019/06/28/00/17/architecture-4303279_1280.jpg");
      background-size: cover;
    }
  
    .inside-contact input,
    .inside-contact textarea {
      background-color: #fff;
    }
  }
  
  
  #carouselExampleIndicators {
    padding-top: 0px !important;
  }
  .main {
    position: relative;
  }
  .manage{
    margin: 50 !important;
    position: absolute;
    top: 4%;
    left: 20%;
  }

  .conatctbg{
    width: 100% !important;
    height: 280px;
  }
