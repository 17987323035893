.hotelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hotelWrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.bookNow {
  position: absolute;
  top: 10px;
  right: 0;
  border: none;
  padding: 10px 20px;
  background-color: #0071c2;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.hotelTitle {
  
  font-size: 24px;
  color: #0071c2;
}
.hotelTitl {
  margin: 30px;
  font-size: 40px;
  color: #0071c2;
}

h1{
  text-transform: uppercase !important;
  font-weight:700;
}
.hotelAddress {
  font-size: 20px;
  margin: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.hotelDistance {
  
  color: #353535;
  font-weight: 500;
}
.Distance {
  margin-left: 40px;
  color: #131314;
  font-weight: 500;
}

.hotelPriceHighlight {
  margin: 10%;
  color: #008009;
  font-weight: 500;
}

.hotelImages {
  display: flex;  
  justify-content: space-between;
}

.hotelImgWrapper {
  width: 30%;
  margin-bottom: 25px;
}

.hotelImg {
  border-radius: 2%;
  object-fit: cover;
  cursor: pointer;
  gap: 10px;
  margin: 10px !important;
}

.hotelDetails {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.hotelDetailsTexts {
  flex: 3;
}

.hotelDesc {
  font-size: 16px;
  margin-top: 20px;
}

.hotelDetailsPrice {
  flex: 1;
  background-color: #ebf3ff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hotelDetailsPrice > h1 {
  font-size: 18px;
  color: #555;
}
.hotelDetailsPrice > span {
  font-size: 14px;
}
.hotelDetailsPrice > h2 {
  font-weight: 300;
}
.hotelDetailsPrice > button {
  border: none;
  padding: 10px 20px;
  background-color: #0071c2;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.slider{
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 92vh;
  z-index: 999;
  display: flex;
  align-items: center;
}

.sliderWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderImg{
  width: 144%;
    height: 85%;
  max-height: 550px;
  max-width: 1244px;
  border-radius: 2%;
}

.close{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: lightgray;
  cursor: pointer;
}

.arrow{
  margin: 10px;
  font-size: 30px;
  color: lightgray;
  cursor: pointer;
}

@keyframes infiniteScroll {
  from {transform: translateX(40%)}
  to {transform: translateX(-0%)}
}
.container {
  width: 100%;  
}
.horizontal-scrolling-items {
  display: flex;
  font-size: 40px;
  overflow-x: hidden;
  animation-name: infiniteScroll;
  animation-duration: 20s;
  animation-delay: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.horizontal-scrolling-items__item {
  white-space: nowrap;
} 