.homeContainer{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.homeTitle{
  width: 1024px;
  font-size: 20px;
}
.Errorimg{
  width: 115%;
  height: 90vh;
  max-width: 1200px;
}
.cabut{
  border: 2px solid #48a0c6;
  border-radius: 5%;
  padding: 5px;
  margin: 15px;
  margin-bottom: 50px;
  background-color: white;
  color: #48a0c6;
}

.settle-privacy{
  color:  #48a0c6;
  border: none;
  margin: 25px;
  background-color: white;
}
.priv{
  color:  #48a0c6;

}